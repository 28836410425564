import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {CuentaBancariaService} from "../../../../services/_Sis/CuentaBancariaService/CuentaBancariaService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Subtitles,
	Business,
	Spellcheck
} from "@material-ui/icons";
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";

class ModalCuentaBancaria extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			//Cuenta bancaria
			id_cuenta_bancaria: (props.item || {}).id_cuenta_bancaria,
			id_proveedor: '',
			nombre_comercial: '',
			nombre_recibe_pago: '',
			rfc: '',
			id_cat_banco: '',
			numero_cuenta: '',
			numero_tarjeta: '',
			clabe: '',
			id_cat_area_proyecto: '',
			
			cat_proveedor: [],
			cat_banco: [],
			cat_area_proyecto: [],
			
			//Direccion
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			cat_estado: [],
			cat_municipio: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	ListEstado = () => {
		CatService.ListEstado().then((response) => {
			this.setState({
				cat_estado: response.data
			});
		});
	};
	
	ListMunicipio = (e, id_cat_estado) => {
		
		if (!id_cat_estado) {
			id_cat_estado = e.target.value;
		}
		
		CatService.ListMunicipio(id_cat_estado).then((response) => {
			this.setState({
				cat_municipio: response.data
			});
		});
	};
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	save = () => {
		if (this.state.id_cuenta_bancaria > 0) {
			CuentaBancariaService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			CuentaBancariaService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		
		this.ListEstado();
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
				id_proveedor: item.id_proveedor || '',
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
			});
		});
		
		
		if (this.state.id_cat_estado > 0)
			this.ListMunicipio(null, this.state.id_cat_estado);
		
		this.setState({
			id_cuenta_bancaria: '',
			nombre_comercial: '',
			nombre_recibe_pago: '',
			rfc: '',
			id_cat_banco: '',
			numero_cuenta: '',
			numero_tarjeta: '',
			clabe: '',
			id_cat_area_proyecto: '',
			
			//Direccion
			id_cat_estado: '',
			id_cat_municipio: '',
			calle: '',
			numero_exterior: '',
			numero_interior: '',
			codigo_postal: '',
			colonia: '',
			referencia: '',
			
			activo: true
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.ListEstado();
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
				id_proveedor: item.id_proveedor || ''
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
				id_cat_banco: item.id_cat_banco || '',
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		
		if (this.state.id_cat_estado > 0)
			this.ListMunicipio(null, this.state.id_cat_estado);
		
		this.setState({
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			nombre_comercial: item.nombre_comercial || '',
			nombre_recibe_pago: item.nombre_recibe_pago || '',
			rfc: item.rfc || '',
			numero_cuenta: item.numero_cuenta || '',
			numero_tarjeta: item.numero_tarjeta || '',
			clabe: item.clabe || '',
			
			//Direccion
			id_cuenta_bancaria_direccion: item.id_cuenta_bancaria_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			activo: (Number(item.activo) === 1),
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.ListEstado();
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
				id_proveedor: item.id_proveedor || ''
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
				id_cat_banco: item.id_cat_banco || '',
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		
		
		if (this.state.id_cat_estado > 0)
			this.ListMunicipio(null, this.state.id_cat_estado);
		
		this.setState({
			id_cuenta_bancaria: item.id_cuenta_bancaria || '',
			nombre_comercial: item.nombre_comercial || '',
			nombre_recibe_pago: item.nombre_recibe_pago || '',
			rfc: item.rfc || '',
			numero_cuenta: item.numero_cuenta || '',
			numero_tarjeta: item.numero_tarjeta || '',
			clabe: item.clabe || '',
			
			//Direccion
			id_cuenta_bancaria_direccion: item.id_cuenta_bancaria_direccion || '',
			id_cat_estado: item.id_cat_estado || '',
			id_cat_municipio: item.id_cat_municipio || '',
			calle: item.calle || '',
			numero_exterior: item.numero_exterior || '',
			numero_interior: item.numero_interior || '',
			codigo_postal: item.codigo_postal || '',
			colonia: item.colonia || '',
			referencia: item.referencia || '',
			
			activo: (Number(item.activo) === 1),
		});
		
		this.open();
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	asignarUsuario = (id_usuario, id_usuario_autorizado) => {
		let usuarios = FieldsJs.Copy(this.state.lista_usuario_autorizado);
		let users = FieldsJs.Copy(this.state.usuarios_autorizados);
		let banduser = false;
		let userkey = 0;
		for (let i = 0; i < users.length; i++) {
			if (Number(users[i].id_usuario) === Number(id_usuario)) {
				banduser = true;
				userkey = i;
			}
		}
		if (!banduser) {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = true;
					if (id_usuario_autorizado) {
						usuarios[i].id_usuario = id_usuario_autorizado;
					}
					users.push(usuarios[i]);
				}
			}
		} else {
			for (let i = 0; i < usuarios.length; i++) {
				if (Number(usuarios[i].id_usuario) === Number(id_usuario)) {
					usuarios[i].check = false;
				}
			}
			users.splice(userkey, 1);
			//this.showSnackBars('error', 'El usuario ya se encuentra seleccionado.');
		}
		this.setState({
			lista_usuario_autorizado: usuarios,
			usuarios_autorizados: users,
			id_usuario_autorizado: ''
		});
		console.log("ASIGNARUSUARIO", users, usuarios, this.state.usuarios_autorizados);
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cuenta_bancaria}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'md'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Cuenta Bancaria'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountBalance className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth >
											<InputLabel>Beneficiario</InputLabel>
											<Select
												native
												value={this.state.id_proveedor}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_proveedor"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_proveedor.map(option => (
													<option key={option.id_proveedor}
													        value={option.id_proveedor}>
														{option.nombre_razon_social}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_recibe_pago"
										           onChange={this.handleChange}
										           label="Propietario de la cuenta" autoComplete={'off'}
										           value={this.state.nombre_recibe_pago}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Business className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="nombre_comercial"
										           onChange={this.handleChange}
										           label="Nombre Comercial" autoComplete={'off'}
										           value={this.state.nombre_comercial}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>*/}
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Subtitles className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="rfc"
										           onChange={this.handleChange}
										           label="RFC" autoComplete={'off'}
										           value={this.state.rfc}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountBalance className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth >
											<InputLabel>Banco</InputLabel>
											<Select
												native
												value={this.state.id_cat_banco}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_banco"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_banco.map(option => (
													<option key={option.id_cat_banco}
													        value={option.id_cat_banco}>
														{option.banco}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_cuenta"
										           onChange={this.handleChange}
											//inputProps={{maxLength: 10}}
											        onKeyPress={EnteroSolo}
											        label="Número de Cuenta" autoComplete={'off'}
											        value={this.state.numero_cuenta}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_tarjeta"
										           onChange={this.handleChange}
											//inputProps={{maxLength: 10}}
											        onKeyPress={EnteroSolo}
											        label="Número de Tarjeta" autoComplete={'off'}
											        value={this.state.numero_tarjeta}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="clabe"
										           onChange={this.handleChange}
											//inputProps={{maxLength: 10}}
											        onKeyPress={EnteroSolo}
											        label="Clabe" autoComplete={'off'}
											        value={this.state.clabe}
											        disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Spellcheck className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth >
											<InputLabel>Area / Proyecto</InputLabel>
											<Select
												native
												value={this.state.id_cat_area_proyecto}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_area_proyecto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_area_proyecto.map(option => (
													<option key={option.id_cat_area_proyecto}
													        value={option.id_cat_area_proyecto}>
														{option.clave +' - '+ option.area_proyecto}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>*/}
					
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_cuenta_bancaria}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalCuentaBancaria.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	ListProveedor: PropTypes.func.isRequired,
	ListBanco: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalCuentaBancaria;
