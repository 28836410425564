import {HttpRequest} from '../../../settings/HttpRequest/HttpRequest';

export const CatService = {
	Cfg: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/config').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListPeriodoPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/periodo_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstado: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estado').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListMunicipio: (id_estado) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/municipio/' + id_estado).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSexo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/sexo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoSangre: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_sangre').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoUsuario: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuario: (id_cat_tipo_usuario) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get(id_cat_tipo_usuario ? 'cat/usuario/'+id_cat_tipo_usuario:'cat/usuario').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListGiroCliente: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/giro_cliente').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListUsuarioEjecutivo: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/usuario_ejecutivo').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoPersona: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_persona').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListClase: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/clase').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSubClase: (clase) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/subclase/'+clase).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListBanco: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/banco').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListAreaProyecto: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/area_proyecto').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListProveedor: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/proveedor').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListTipoPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/tipo_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEtapaProyecto: (id_cat_area_proyecto) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/etapa_proyecto/'+id_cat_area_proyecto).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListSubEtapaProyecto: (id_cat_etapa_proyecto) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/subetapa_proyecto/'+id_cat_etapa_proyecto).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListCatEstatusSolicitudPago: () => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estatus_solicitud_pago').then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
	ListEstatusSolicitudPago: (id_solicitud_pago) => {
		return new Promise((resolve, reject) => {
			HttpRequest.get('cat/estatus_solicitud_pago/'+id_solicitud_pago).then((response) => {
				resolve(response);
			}).catch((error) => {
				reject(error);
			});
		});
	},
};
