import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import Grid from "@material-ui/core/Grid";
import DraggableModal from "../../../Include/DraggableModal/DraggableModal";
import {EnteroSolo, FieldsJs} from "../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import {SolicitudPagoService} from "../../../../services/_Sis/SolicitudPagoService/SolicitudPagoService";
import {BotonAccionAbrirModal, BotonAccionFooterModal, TituloHeaderModal} from "../../../Include/MiniComponents/GlobalComponent";
import {
	AccountBalance,
	HdrWeakOutlined,
	Person,
	Comment,
	FiberManualRecord,
	AccountTree,
	DeleteOutlined,
	AddOutlined, AttachMoney, CreditCard, CancelOutlined, SaveOutlined
} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import {PopupService} from "../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../settings/Config/Config";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Zoom from '@material-ui/core/Zoom/index';
import {DropzoneArea} from 'material-ui-dropzone';
import {CatService} from "../../../../services/_Cat/CatService/CatService";
import EditOutlined from "@material-ui/icons/EditOutlined";

class ModalSolicitudAceptada extends Component {
	
	state = {};
	
	Usr = {};
	
	constructor(props) {
		super(props);
		
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_solicitud_pago: props.item.id_solicitud_pago || '',
			no_recibo: '',
			id_usuario: '',
			id_cat_area_proyecto: '',
			descripcion: '',
			descripcion_general: '',
			id_proveedor: '',
			beneficiario: '',
			beneficiario_partida: '',
			id_proveedor_partida: '',
			cantidad: '',
			precio_unitario: '',
			importe: '',
			id_cat_tipo_pago: '',
			factura: '',
			id_cat_banco: '',
			numero_tarjeta: '',
			usuario: '',
			banco: '',
			proveedor: '',
			tipo_pago: '',
			id_cat_clasificacion_pago: '',
			observaciones: '',
			code: '',
			key: null,
			id_cat_etapa_proyecto: '',
			id_cat_subetapa_proyecto: '',
			id_cat_clase: '',
			id_cat_subclase: '',
			id_cat_cuenta_bancaria: '',
			
			id_cat_area_proyecto_partida: '',
			id_cat_etapa_proyecto_partida: '',
			id_cat_subetapa_proyecto_partida: '',
			id_cat_clase_partida: '',
			id_cat_subclase_partida: '',
			cat_etapa_proyecto_partida: [],
			cat_subetapa_proyecto_partida: [],
			cat_subclase_partida: [],
			
			partidas: [],
			cat_usuario: [],
			cat_tipo_pago: [],
			cat_banco: [],
			cat_area_proyecto: [],
			cat_proveedor: [],
			cat_etapa_proyecto: [],
			cat_subetapa_proyecto: [],
			cat_clase: [],
			cat_subclase: [],
			cat_cuentas_bancarias: [],
			
			activo: true,
			
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	
	is_view = () => {
		return this.props.tipo === 'view';
	};
	
	is_root = () => {
		return FieldsJs.inArray([1], this.Usr.id_cat_tipo_usuario);
	};
	
	is_admin = () => {
		return FieldsJs.inArray([1, 2], this.Usr.id_cat_tipo_usuario);
	};
	
	getNoRecibo = () => {
		SolicitudPagoService.NoRecibo(this.state).then((response) => {
			this.setState({no_recibo:response.data, code: response.data });
		}).catch((error) => {
			this.props.showSnackBars('error', error.mensaje);
		});
	}
	
	save = () => {
		if (this.state.id_solicitud_pago > 0) {
			SolicitudPagoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			SolicitudPagoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		
		this.props.ListProveedor().then((response) => {
			this.setState({
				cat_proveedor: response.data,
			});
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
			});
			if(!FieldsJs.inArray([1,2], this.Usr.id_cat_tipo_usuario)) {
				this.setState({
					id_usuario: this.Usr.id_usuario
				});
			};
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
			});
		});
		
		this.props.ListClase().then((response) => {
			this.setState({
				cat_clase: response.data,
			});
		});
		
		this.setState({
			id_solicitud_pago: '',
			no_recibo: '',
			id_cat_area_proyecto: '',
			observaciones: '',
			id_cat_etapa_proyecto: '',
			id_cat_subetapa_proyecto: '',
			id_cat_clase: '',
			id_cat_subclase: '',
			partidas: [],
			
			id_cat_clasificacion_pago: '',
			id_cat_area_proyecto_partida: '',
			id_cat_etapa_proyecto_partida: '',
			id_cat_subetapa_proyecto_partida: '',
			id_cat_clase_partida: '',
			id_cat_subclase_partida: '',
			cat_etapa_proyecto_partida: [],
			cat_subetapa_proyecto_partida: [],
			cat_subclase_partida: [],
			cat_cuentas_bancarias: [],
			
			activo: true
		});
		
		this.getNoRecibo();
		
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		
		this.props.ListProveedor().then((response) => {
			let cbp = [];
			
			if (FieldsJs.Field(item.id_proveedor)) {
				for (let i = 0; i < response.data.length; i++) {
					if (Number(item.id_proveedor) === Number(response.data[i].id_proveedor)) {
						cbp = response.data[i].cuentas_bancarias
					}
				}
			}
			this.setState({
				cat_proveedor: response.data,
				id_proveedor: item.id_proveedor ?? '',
				cat_cuentas_bancarias: cbp,
				id_cuenta_bancaria: item.id_cuenta_bancaria
			});
			
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
				id_usuario: item.id_usuario || ''
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
				id_cat_banco: item.id_cat_banco ?? ''
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || ''
			});
		});
		
		this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto: response.data,
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || ''
			});
		})
	
		if (FieldsJs.Field(item.id_cat_subetapa_proyecto)) {
			this.props.ListSubEtapaProyecto(item.id_cat_etapa_proyecto).then((response) => {
				this.setState({
					cat_subetapa_proyecto: response.data,
					id_cat_subetapa_proyecto: item.id_cat_subetapa_proyecto || ''
				});
			})
		} else {
			this.setState({
				id_cat_subetapa_proyecto: ''
			});
		}
		
		this.props.ListClase().then((response) => {
			this.setState({
				cat_clase: response.data,
				id_cat_clase: item.id_cat_clase || ''
			});
		});
	
		if (FieldsJs.Field(item.id_cat_clase)) {
			this.props.ListSubClase(item.id_cat_clase).then((response) => {
				this.setState({
					cat_subclase: response.data,
					id_cat_subclase: item.id_cat_subclase || ''
				});
			})
		} else {
			this.setState({
				id_cat_subclase: ''
			});
		}
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			no_recibo: item.no_recibo || '',
			beneficiario: item.beneficiario || '',
			numero_tarjeta: item.numero_tarjeta || '',
			observaciones: item.observaciones || '',
			partidas: item.partidas || [],
			
			id_cat_clasificacion_pago: item.id_cat_clasificacion_pago ?? '',
			id_cat_area_proyecto_partida: '',
			id_cat_etapa_proyecto_partida: '',
			id_cat_subetapa_proyecto_partida: '',
			id_cat_clase_partida: '',
			id_cat_subclase_partida: '',
			cat_etapa_proyecto_partida: [],
			cat_subetapa_proyecto_partida: [],
			cat_subclase_partida: [],
			
			activo: true
		});
		
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		
		this.props.ListProveedor().then((response) => {
			let cbp = [];
			
			if (FieldsJs.Field(item.id_proveedor)) {
				for (let i = 0; i < response.data.length; i++) {
					if (Number(item.id_proveedor) === Number(response.data[i].id_proveedor)) {
						cbp = response.data[i].cuentas_bancarias
					}
				}
			}
			this.setState({
				cat_proveedor: response.data,
				id_proveedor: item.id_proveedor ?? '',
				cat_cuentas_bancarias: cbp,
				id_cuenta_bancaria: item.id_cuenta_bancaria
			});
			
		});
		
		this.props.ListUsuario().then((response) => {
			this.setState({
				cat_usuario: response.data,
				id_usuario: item.id_usuario || ''
			});
		});
		
		this.props.ListBanco().then((response) => {
			this.setState({
				cat_banco: response.data,
				id_cat_banco: item.id_cat_banco ?? ''
			});
		});
		
		this.props.ListTipoPago().then((response) => {
			this.setState({
				cat_tipo_pago: response.data,
			});
		});
		
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || ''
			});
		});
		
		this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto: response.data,
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || ''
			});
		})
		
		if (FieldsJs.Field(item.id_cat_subetapa_proyecto)) {
			this.props.ListSubEtapaProyecto(item.id_cat_etapa_proyecto).then((response) => {
				this.setState({
					cat_subetapa_proyecto: response.data,
					id_cat_subetapa_proyecto: item.id_cat_subetapa_proyecto || ''
				});
			})
		} else {
			this.setState({
				id_cat_subetapa_proyecto: ''
			});
		}
		
		this.props.ListClase().then((response) => {
			this.setState({
				cat_clase: response.data,
				id_cat_clase: item.id_cat_clase || ''
			});
		});
		
		if (FieldsJs.Field(item.id_cat_clase)) {
			this.props.ListSubClase(item.id_cat_clase).then((response) => {
				this.setState({
					cat_subclase: response.data,
					id_cat_subclase: item.id_cat_subclase || ''
				});
			})
		} else {
			this.setState({
				id_cat_subclase: ''
			});
		}
		
		this.setState({
			id_solicitud_pago: item.id_solicitud_pago || '',
			no_recibo: item.no_recibo || '',
			beneficiario: item.beneficiario || '',
			numero_tarjeta: item.numero_tarjeta || '',
			observaciones: item.observaciones || '',
			partidas: item.partidas || [],
			
			id_cat_clasificacion_pago: item.id_cat_clasificacion_pago ?? '',
			id_cat_area_proyecto_partida: '',
			id_cat_etapa_proyecto_partida: '',
			id_cat_subetapa_proyecto_partida: '',
			id_cat_clase_partida: '',
			id_cat_subclase_partida: '',
			cat_etapa_proyecto_partida: [],
			cat_subetapa_proyecto_partida: [],
			cat_subclase_partida: [],
			
			activo: true
		});
		
		
		this.open();
	};
	
	delete = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar el contacto?').then((r) => {
				if (r.button === 'Aceptar') {
					let lista_contactos = FieldsJs.Copy(this.state.lista_contactos);
					
					lista_contactos.splice(key, 1);
					
					this.setState({
						lista_contactos: lista_contactos
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	is_edited_product = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	agregarPartida = () => {
		try {
			
			if (!this.state.descripcion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Descripción.'
				})
			}
			if (!this.state.id_cat_area_proyecto_partida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Proyecto.'
				})
			}
			if (!this.state.id_proveedor_partida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Proveedor.'
				})
			}
			if (!this.state.beneficiario_partida) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Beneficiario.'
				})
			}
			if (!this.state.cantidad) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Cantidad.'
				})
			}
			if (!this.state.precio_unitario) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Precio Unitario.'
				})
			}
			if (!this.state.importe) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Importe.'
				})
			}
			if (!this.state.factura) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Factura.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.partidas);
			let proyecto = this.state.cat_area_proyecto.find(obj => Number(obj.id_cat_area_proyecto) === Number(this.state.id_cat_area_proyecto_partida));
			let proveedor = this.state.cat_proveedor.find(obj => Number(obj.id_proveedor) === Number(this.state.id_proveedor_partida));
			let etapa = this.state.cat_etapa_proyecto_partida.find(obj => Number(obj.id_cat_etapa_proyecto) === Number(this.state.id_cat_etapa_proyecto_partida));
			let subetapa = this.state.cat_subetapa_proyecto_partida.find(obj => Number(obj.id_cat_subetapa_proyecto) === Number(this.state.id_cat_subetapa_proyecto_partida));
			let clase = this.state.cat_clase.find(obj => Number(obj.id_cat_clase) === Number(this.state.id_cat_clase_partida));
			let subclase = this.state.cat_subclase_partida.find(obj => Number(obj.id_cat_subclase) === Number(this.state.id_cat_subclase_partida));
			
			if (this.is_edited_partida()) {
				for (let i = 0; i < partidas.length; i++) {
					let item = partidas[i];
					if (this.state.key === i) {
						item.descripcion = this.state.descripcion || '';
						item.id_proveedor_partida = this.state.id_proveedor_partida || '';
						item.cantidad = this.state.cantidad || '';
						item.precio_unitario = this.state.precio_unitario || '';
						item.importe = this.state.importe || '';
						item.id_cat_tipo_pago = this.state.id_cat_tipo_pago || '';
						item.factura = this.state.factura || '';
						item.proveedor = proveedor ? proveedor.nombre_razon_social : '';
						item.beneficiario_partida = this.state.beneficiario_partida || '';
						item.tipo_pago = this.state.tipo_pago || '';
						item.id_cat_area_proyecto = this.state.id_cat_area_proyecto_partida || '';
						item.area_proyecto = proyecto ? proyecto.area_proyecto : '';
						item.etapa = etapa ? etapa.etapa : '';
						item.subetapa = subetapa ? subetapa.subetapa : '';
						item.clase = clase ? clase.clase : '';
						item.subclase = subclase ? subclase.subclase : '';
						item.id_cat_etapa_proyecto = this.state.id_cat_etapa_proyecto_partida || '';
						item.id_cat_subetapa_proyecto = this.state.id_cat_subetapa_proyecto_partida || '';
						item.id_cat_clase = this.state.id_cat_clase_partida || '';
						item.id_cat_subclase = this.state.id_cat_subclase_partida || '';
						item.activo = 1;
					}
				}
			} else {
				let item = {
					descripcion: this.state.descripcion || '',
					id_proveedor_partida: this.state.id_proveedor_partida || '',
					cantidad: this.state.cantidad || '',
					precio_unitario: this.state.precio_unitario || '',
					importe: this.state.importe || '',
					id_cat_tipo_pago: this.state.id_cat_tipo_pago || '',
					factura: this.state.factura || '',
					proveedor: proveedor ? proveedor.nombre_razon_social : '',
					beneficiario_partida: this.state.beneficiario_partida || '',
					tipo_pago: this.state.tipo_pago || '',
					id_cat_area_proyecto: this.state.id_cat_area_proyecto_partida || '',
					area_proyecto: proyecto ? proyecto.area_proyecto : '',
					etapa: etapa ? etapa.etapa : '',
					subetapa: subetapa ? subetapa.subetapa : '',
					clase: clase ? clase.clase : '',
					subclase: subclase ? subclase.subclase : '',
					id_cat_etapa_proyecto: this.state.id_cat_etapa_proyecto_partida || '',
					id_cat_subetapa_proyecto: this.state.id_cat_subetapa_proyecto_partida || '',
					id_cat_clase: this.state.id_cat_clase_partida || '',
					id_cat_subclase: this.state.id_cat_subclase_partida || '',
					activo: 1,
				};
				
				partidas.push(item);
				
			}
			
			this.setState({
				key: null,
				descripcion: '',
				id_proveedor_partida: '',
				cantidad: '',
				precio_unitario: '',
				importe: '',
				id_cat_tipo_pago: '',
				factura: '',
				proveedor: '',
				beneficiario_partida: '',
				//tipo_pago: '',
				partidas: partidas,
				id_cat_area_proyecto_partida: '',
				id_cat_etapa_proyecto_partida: '',
				id_cat_subetapa_proyecto_partida: '',
				id_cat_clase_partida: '',
				id_cat_subclase_partida: '',
				cat_etapa_proyecto_partida: [],
				cat_subetapa_proyecto_partida: [],
				cat_subclase_partida: [],
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	editar_partida = (value, key) => {
		try {
			this.handleChangeEtapaProyectoPartida(value.id_cat_area_proyecto);
			this.handleChangeSubEtapaProyectoPartida(value.id_cat_etapa_proyecto);
			this.handleChangeSubClaseProyectoPartida(value.id_cat_clase);
			console.log('editar partida', value);
			this.setState({
				key: key,
				descripcion: value.descripcion,
				id_proveedor_partida: value.id_proveedor_partida,
				cantidad: value.cantidad,
				precio_unitario: value.precio_unitario,
				importe: value.importe,
				id_cat_tipo_pago: value.id_cat_tipo_pago,
				factura: value.factura,
				proveedor: value.proveedor,
				beneficiario_partida: value.beneficiario_partida,
				//tipo_pago: value.tipo_pago,
				id_cat_area_proyecto_partida: value.id_cat_area_proyecto,
				area_proyect: value.area_proyecto,
				etapa: value.etapa,
				subetapa: value.subetapa,
				clase: value.clase,
				subclase: value.subclase,
				id_cat_etapa_proyecto_partida: value.id_cat_etapa_proyecto,
				id_cat_subetapa_proyecto_partida: value.id_cat_subetapa_proyecto,
				id_cat_clase_partida: value.id_cat_clase,
				id_cat_subclase_partida: value.id_cat_subclase,
				activo: 1,
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	concatTipoPago = () => {
		setTimeout(() => {
			let numero = this.state.code;
			let pro = this.state.cat_area_proyecto.find((option) => Number(this.state.id_cat_area_proyecto) === Number(option.id_cat_area_proyecto));
			if (pro) {
				numero = pro.area_proyecto.substr(0, 4) + numero;
			}
			let partidas = FieldsJs.Copy(this.state.partidas);
			for (let i = 0; i < partidas.length; i++) {
				let tipo = this.state.cat_tipo_pago.find((option) => Number(partidas[i].id_cat_tipo_pago) === Number(option.id_cat_tipo_pago));
				if (tipo) {
					numero = tipo.tipo_pago.substr(0, 4) +'-'+ numero;
				}
			}
		
			this.setState({
				no_recibo: numero
			});
		}, 900);
	}

	eliminarPartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la partida?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.partidas);
					
					partidas.splice(key, 1);
					
					this.setState({
						partidas: partidas
					});
					
					setTimeout(() => {
						let desglose = this.calcularDesgoseTotales();
						this.setState({
							desglose: desglose
						});
					}, 800);
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	selectUsuario = (id_usuario) => {
		let user = '';
		for (let i = 0; i < this.state.cat_usuario.length; i++) {
			if (Number(id_usuario) === Number(this.state.cat_usuario[i].id_usuario)) {
				user = this.state.cat_usuario[i].nombre_completo;
			}
		}
		
		this.setState({
			usuario: user
		});
	}
	
	selectProveedor = (id_proveedor) => {
		let prov = '';
		let cb = '';
		for (let i = 0; i < this.state.cat_proveedor.length; i++) {
			if (Number(id_proveedor) === Number(this.state.cat_proveedor[i].id_proveedor)) {
				prov = this.state.cat_proveedor[i].nombre_razon_social;
				cb = this.state.cat_proveedor[i].cuentas_bancarias;
			}
		}
		
		if (cb.length === 1) {
			this.setState({
				id_cuenta_bancaria: cb[0].id_cuenta_bancaria,
				beneficiario: cb[0].nombre_recibe_pago || '',
				id_cat_banco: cb[0].id_cat_banco || '',
				numero_tarjeta: cb[0].numero_tarjeta || ''
			});
		} else {
			this.setState({
				beneficiario: '',
				id_cat_banco: '',
				numero_tarjeta: ''
			});
		}
		
		this.setState({
			proveedor: prov,
			cat_cuentas_bancarias: cb
		});
	}
	
	selectTipoPago = (id_cat_tipo_pago) => {
		let tp = '';
		for (let i = 0; i < this.state.cat_tipo_pago.length; i++) {
			if (Number(id_cat_tipo_pago) === Number(this.state.cat_tipo_pago[i].id_cat_tipo_pago)) {
				tp = this.state.cat_tipo_pago[i].tipo_pago;
			}
		}
		this.setState({
			factura: Number(id_cat_tipo_pago) === 2 ? '1':'2',
			tipo_pago: tp
		});
	}
	
	selectBanco = (id_cat_banco) => {
		let bc = '';
		for (let i = 0; i < this.state.cat_banco.length; i++) {
			if (Number(id_cat_banco) === Number(this.state.cat_banco[i].id_cat_banco)) {
				bc = this.state.cat_banco[i].banco;
			}
		}
		this.setState({
			banco: bc
		});
	}
	
	handleChangeNoRecibo = (proyecto) => {
		this.concatTipoPago(proyecto);
	}
	
	handleChangeEtapaProyecto = (proyecto) => {
		this.props.ListEtapaProyecto(proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto:response.data
			})
		})
	}
	
	handleChangeEtapaProyectoPartida = (proyecto) => {
		this.props.ListEtapaProyecto(proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto_partida:response.data
			})
		})
	}
	
	handleChangeSubEtapaProyecto = (etapa) => {
		this.props.ListSubEtapaProyecto(etapa).then((response) => {
			this.setState({
				cat_subetapa_proyecto:response.data
			})
		})
	}
	handleChangeSubEtapaProyectoPartida = (etapa) => {
		this.props.ListSubEtapaProyecto(etapa).then((response) => {
			this.setState({
				cat_subetapa_proyecto_partida:response.data
			})
		})
	}
	
	handleChangeSubClaseProyecto = (clase) => {
		this.props.ListSubClase(clase).then((response) => {
			this.setState({
				cat_subclase:response.data
			})
		})
	}
	
	handleChangeSubClaseProyectoPartida = (clase) => {
		this.props.ListSubClase(clase).then((response) => {
			this.setState({
				cat_subclase_partida:response.data
			})
		})
	}
	
	handleChangeCuentaBancaria = (id_cuenta_bancaria) => {
		let cbc = FieldsJs.Copy(this.state.cat_cuentas_bancarias);
		if (cbc.length > 0) {
			for (let i = 0; i < cbc.length; i++) {
				if (Number(id_cuenta_bancaria) === Number(cbc[i].id_cuenta_bancaria)) {
					this.setState({
						beneficiario: cbc[i].nombre_recibe_pago || '',
						id_cat_banco: cbc[i].id_cat_banco || '',
						numero_tarjeta: cbc[i].numero_tarjeta || ''
					});
				}
			}
		} else {
			this.setState({
				beneficiario: '',
				id_cat_banco: '',
				numero_tarjeta: ''
			});
		}
	}
	
	handleChangeImporte = () => {
		if (FieldsJs.Field(this.state.cantidad) && FieldsJs.Field(this.state.precio_unitario)) {
			this.setState({
				importe: Number(this.state.cantidad) * Number(this.state.precio_unitario),
			});
		}
	}
	
	handleChangeTipoPago = (tipo) => {
		if (Number(tipo) === 1) {
			this.handleChangeEtapaProyectoPartida(this.state.id_cat_area_proyecto);
			this.handleChangeSubEtapaProyectoPartida(this.state.id_cat_etapa_proyecto);
			this.handleChangeSubClaseProyectoPartida(this.state.id_cat_clase);
			this.setState({
				id_cat_area_proyecto_partida: this.state.id_cat_area_proyecto,
				id_cat_etapa_proyecto_partida: this.state.id_cat_etapa_proyecto,
				id_cat_subetapa_proyecto_partida: this.state.id_cat_subetapa_proyecto,
				id_cat_clase_partida: this.state.id_cat_clase,
				id_cat_subclase_partida: this.state.id_cat_subclase,
				id_proveedor_partida: this.state.id_proveedor,
				beneficiario_partida: this.state.beneficiario
			});
		}
	}
	
	tipoUsuario = () => {
		return this.Usr.id_cat_tipo_usuario !== 1 && this.Usr.id_cat_tipo_usuario !== 2;
	}
	
	is_edited_partida = () => {
		return this.state.key !== null && this.state.key > -1;
	};
	
	edit_cancel = () => {
		this.setState({
			key: null,
			descripcion: '',
			id_proveedor_partida: '',
			cantidad: '',
			precio_unitario: '',
			importe: '',
			id_cat_tipo_pago: '',
			factura: '',
			proveedor: '',
			beneficiario_partida: '',
			//tipo_pago: '',
			id_cat_area_proyecto_partida: '',
			id_cat_etapa_proyecto_partida: '',
			id_cat_subetapa_proyecto_partida: '',
			id_cat_clase_partida: '',
			id_cat_subclase_partida: '',
			cat_etapa_proyecto_partida: [],
			cat_subetapa_proyecto_partida: [],
			cat_subclase_partida: [],
		});
	};
	
	render() {
		
		const is_root = this.is_root();
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		return (
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_solicitud_pago}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'lg'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Solicitud de Pago'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						
						<Grid container spacing={2}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FiberManualRecord className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="no_recibo"
										           onChange={this.handleChange}
										           label="No. Recibo" autoComplete={'off'}
										           value={this.state.no_recibo}
										           disabled={!this.is_admin}
										           
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Usuario Solicitante</InputLabel>
											<Select
												native
												value={this.state.id_usuario}
												onChange={(e) => {
													this.handleChange(e);
													this.selectUsuario(e.target.value);
												}}
												name="id_usuario"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_usuario.map(option => (
													<option key={option.id_usuario}
													        value={option.id_usuario}>
														{option.nombre_completo}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Proyecto</InputLabel>
											<Select
												native
												value={this.state.id_cat_area_proyecto}
												onChange={(e) => {
													this.handleChange(e);
													this.handleChangeNoRecibo(e.target.value);
													this.handleChangeEtapaProyecto(e.target.value);
												}}
												name="id_cat_area_proyecto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_area_proyecto.map(option => (
													<option key={option.id_cat_area_proyecto}
													        value={option.id_cat_area_proyecto}>
														{option.area_proyecto}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Etapa</InputLabel>
											<Select
												native
												value={this.state.id_cat_etapa_proyecto}
												onChange={(e) => {
													this.handleChange(e);
													this.handleChangeSubEtapaProyecto(e.target.value);
												}}
												name="id_cat_etapa_proyecto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_etapa_proyecto.map(option => (
													<option key={option.id_cat_etapa_proyecto}
													        value={option.id_cat_etapa_proyecto}>
														{option.etapa}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Sub-Etapa</InputLabel>
											<Select
												native
												value={this.state.id_cat_subetapa_proyecto}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_subetapa_proyecto"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_subetapa_proyecto.map(option => (
													<option key={option.id_cat_subetapa_proyecto}
													        value={option.id_cat_subetapa_proyecto}>
														{option.subetapa}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Clase</InputLabel>
											<Select
												native
												value={this.state.id_cat_clase}
												onChange={(e) => {
													this.handleChange(e);
													this.handleChangeSubClaseProyecto(e.target.value);
												}}
												name="id_cat_clase"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_clase.map(option => (
													<option key={option.id_cat_clase}
													        value={option.id_cat_clase}>
														{option.clase}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Sub-Clase</InputLabel>
											<Select
												native
												value={this.state.id_cat_subclase}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_subclase"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_subclase.map(option => (
													<option key={option.id_cat_subclase}
													        value={option.id_cat_subclase}>
														{option.subclase}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Proveedor</InputLabel>
											<Select
												native
												value={this.state.id_proveedor}
												onChange={(e) => {
													this.handleChange(e);
													this.selectProveedor(e.target.value);
												}}
												name="id_proveedor"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_proveedor.map(option => (
													<option key={option.id_proveedor}
													        value={option.id_proveedor}>
														{option.nombre_razon_social}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Cuenta Bancaría</InputLabel>
											<Select
												native
												value={this.state.id_cuenta_bancaria}
												onChange={(e) => {
													this.handleChange(e);
													this.handleChangeCuentaBancaria(e.target.value);
												}}
												name="id_cuenta_bancaria"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_cuentas_bancarias.map(option => (
													<option key={option.id_cuenta_bancaria}
													        value={option.id_cuenta_bancaria}>
														{option.nombre_recibe_pago + (option.rfc ? ' - ' + option.rfc : '')}
													</option>
												))}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Person className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField
											type={'text'}
											fullWidth
											name="beneficiario"
											onChange={this.handleChange}
											label="Beneficiario de la cuenta"
											autoComplete={'off'}
											value={this.state.beneficiario}
											disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Banco</InputLabel>
											<Select
												native
												value={this.state.id_cat_banco}
												onChange={(e) => {
													this.handleChange(e);
													this.selectBanco(e.target.value);
												}}
												name="id_cat_banco"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_banco.map(option => (
													<option key={option.id_cat_banco}
													        value={option.id_cat_banco}>
														{option.banco}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<CreditCard className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="numero_tarjeta"
										           onChange={this.handleChange}
										           label="No. Tarjeta / Clabe Interbancaria" autoComplete={'off'}
										           value={this.state.numero_tarjeta}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountBalance className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Clasificación de Pago</InputLabel>
											<Select
												native
												value={this.state.id_cat_clasificacion_pago}
												onChange={(e) => {
													this.handleChange(e);
												}}
												name="id_cat_clasificacion_pago"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												<option key={'1'} value={'1'}>Pago Único</option>
												<option key={'2'} value={'2'}>Multipago</option>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Tipo de Pago</InputLabel>
											<Select
												native
												value={this.state.id_cat_tipo_pago}
												onChange={(e) => {
													this.handleChange(e);
													this.selectTipoPago(e.target.value);
												}}
												name="id_cat_tipo_pago"
												input={<Input/>}
												disabled={this.props.tipo === 'view'}
											>
												<option value=""/>
												{this.state.cat_tipo_pago.map(option => (
													<option key={option.id_cat_tipo_pago}
													        value={option.id_cat_tipo_pago}>
														{option.tipo_pago}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<FiberManualRecord className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="descripcion_general"
										           onChange={this.handleChange}
										           label="Descripción General" autoComplete={'off'}
										           value={this.state.descripcion_general}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<Comment className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<TextField type={'text'} fullWidth name="observaciones"
										           onChange={this.handleChange}
										           label="Observaciones" autoComplete={'off'}
										           value={this.state.observaciones}
										           disabled={this.props.tipo === 'view'}
										/>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left borde-punteado-gray-2 margin-10-T'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={8} lg={8} xl={8}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<FiberManualRecord className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="descripcion"
												           onChange={this.handleChange}
												           label="Descripción" autoComplete={'off'}
												           value={this.state.descripcion}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={4} lg={4} xl={4}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountTree className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Proyecto</InputLabel>
													<Select
														native
														value={this.state.id_cat_area_proyecto_partida}
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeEtapaProyectoPartida(e.target.value);
														}}
														name="id_cat_area_proyecto_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_area_proyecto.map(option => (
															<option key={option.id_cat_area_proyecto}
															        value={option.id_cat_area_proyecto}>
																{option.area_proyecto}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountTree className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Etapa</InputLabel>
													<Select
														native
														value={this.state.id_cat_etapa_proyecto_partida}
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeSubEtapaProyectoPartida(e.target.value);
														}}
														name="id_cat_etapa_proyecto_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_etapa_proyecto_partida.map(option => (
															<option key={option.id_cat_etapa_proyecto}
															        value={option.id_cat_etapa_proyecto}>
																{option.etapa}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountTree className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Sub-Etapa</InputLabel>
													<Select
														native
														value={this.state.id_cat_subetapa_proyecto_partida}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_subetapa_proyecto_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_subetapa_proyecto_partida.map(option => (
															<option key={option.id_cat_subetapa_proyecto}
															        value={option.id_cat_subetapa_proyecto}>
																{option.subetapa}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountTree className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Clase</InputLabel>
													<Select
														native
														value={this.state.id_cat_clase_partida}
														onChange={(e) => {
															this.handleChange(e);
															this.handleChangeSubClaseProyectoPartida(e.target.value);
														}}
														name="id_cat_clase_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_clase.map(option => (
															<option key={option.id_cat_clase}
															        value={option.id_cat_clase}>
																{option.clase}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AccountTree className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Sub-Clase</InputLabel>
													<Select
														native
														value={this.state.id_cat_subclase_partida}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_cat_subclase_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_subclase_partida.map(option => (
															<option key={option.id_cat_subclase}
															        value={option.id_cat_subclase}>
																{option.subclase}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<FormControl fullWidth>
													<InputLabel>Proveedor</InputLabel>
													<Select
														native
														value={this.state.id_proveedor_partida}
														onChange={(e) => {
															this.handleChange(e);
														}}
														name="id_proveedor_partida"
														input={<Input/>}
														disabled={this.props.tipo === 'view'}
													>
														<option value=""/>
														{this.state.cat_proveedor.map(option => (
															<option key={option.id_proveedor}
															        value={option.id_proveedor}>
																{option.nombre_razon_social}
															</option>
														))}
													
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={6} lg={6} xl={6}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<Person className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="beneficiario_partida"
												           onChange={this.handleChange}
												           label="Beneficiario" autoComplete={'off'}
												           value={this.state.beneficiario_partida}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={2} lg={2} xl={2}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<FiberManualRecord className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="cantidad"
												           onChange={this.handleChange}
												           label="Cantidad" autoComplete={'off'}
												           value={this.state.cantidad}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AttachMoney className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="precio_unitario"
												           onChange={this.handleChange}
												           label="Precio Unitario" autoComplete={'off'}
												           value={this.state.precio_unitario}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item className={'w-30-px'}>
												<AttachMoney className={'w-100 text-gray'}/>
											</Grid>
											<Grid item className={'w-100-30-px'}>
												<TextField type={'text'} fullWidth name="importe"
												           onChange={this.handleChange}
												           label="Importe" autoComplete={'off'}
												           value={this.state.importe}
												           disabled={this.props.tipo === 'view'}
												/>
											</Grid>
										</Grid>
									</Grid>
									
									<Grid item className={'text-left'} xs={12} sm={12} md={3} lg={3} xl={3}>
										<Grid container spacing={0} alignItems={"flex-end"}>
											<Grid item className={'text-left'} xs={8} sm={8} md={10} lg={10} xl={10}>
												<Grid container spacing={1} alignItems={"flex-end"}>
													<Grid item className={'w-30-px'}>
														<AccountBalance className={'w-100 text-gray'}/>
													</Grid>
													<Grid item className={'w-100-30-px'}>
														<FormControl fullWidth>
															<InputLabel>Factura</InputLabel>
															<Select
																native
																value={this.state.factura}
																onChange={(e) => {
																	this.handleChange(e);
																}}
																name="factura"
																input={<Input/>}
																disabled={this.props.tipo === 'view'}
															>
																<option value=""/>
																<option key={'1'} value={'1'}>Si</option>
																<option key={'2'} value={'2'}>No</option>
															</Select>
														</FormControl>
													</Grid>
												</Grid>
											</Grid>
											
											<Grid item xs={12} sm={6} md={2} lg={2} xl={2} align={"right"}>
												<div className={'w-auto vertical-inline h-100'}>
													<div className={'v-center'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Cancelar edición"
															children={(
																<span>
															<Fab size="small" color="secondary"
															     aria-label="Cancelar edición"
															     onClick={() => this.edit_cancel()}
															     style={{marginLeft: "15px"}}
															     disabled={!this.is_edited_partida()}>
																<CancelOutlined/>
															</Fab>
														</span>
															)}
														/>
													</div>
													<div className={'v-center'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title={this.is_edited_partida() ? "Actualizar" : "Agregar"}
															children={(
																<Fab size="small" color="secondary"
																     aria-label={this.is_edited_partida() ? "Actualizar" : "Agregar"}
																     onClick={() => this.agregarPartida()}
																     style={{marginLeft: "15px"}}>
																	{this.is_edited_partida() ? (
																		<SaveOutlined/>
																	) : (
																		<AddOutlined/>
																	)}
																</Fab>
															)}
														/>
													</div>
												</div>
											</Grid>
											
										</Grid>
									</Grid>
									
									
									{/*<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
										<Grid container spacing={1} alignItems={"flex-end"}>
											<Grid item>
												<FormGroup row className={'margin-3-L'}>
													<FormControlLabel
														control={
															<Checkbox type="checkbox" name='activo'
															          checked={this.state.activo}
															          onChange={this.handleChange} value="activo"
															          color="primary"
															          disabled={this.props.tipo === 'view'}/>
														}
														label={'Activo'}
													/>
												</FormGroup>
											</Grid>
										</Grid>
									</Grid>*/}
								
								</Grid>
							</Grid>
							
							{this.state.partidas.length > 0 ? (
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<table className={'desing-mark'}>
										<thead>
										<tr>
											<th align={"center"}>No.</th>
											{/*<th align={"center"}>Tipo Pago</th>*/}
											<th align={"center"}>Descripción</th>
											<th align={"center"}>Proyecto</th>
											<th align={"center"}>Etapa</th>
											<th align={"center"}>Sub-etapa</th>
											<th align={"center"}>Clase</th>
											<th align={"center"}>Sub-clase</th>
											<th align={"center"}>Proveedor</th>
											<th align={"center"}>Beneficiario</th>
											<th align={"center"}>Cantidad</th>
											<th align={"center"}>Precio Unitario</th>
											<th align={"center"}>Importe</th>
											<th align={"center"}>Factura</th>
											<th align={"right"}>Acciones</th>
										</tr>
										</thead>
										<tbody>
										{this.state.partidas.map((item, index) => (
												<tr key={index}>
													<td align={"center"}>{index + 1}</td>
													{/*<td align={"center"}>{item.tipo_pago}</td>*/}
													<td align={"center"}>{item.descripcion}</td>
													<td align={"center"}>{item.area_proyecto}</td>
													<td align={"center"}>{item.etapa}</td>
													<td align={"center"}>{item.subetapa}</td>
													<td align={"center"}>{item.clase}</td>
													<td align={"center"}>{item.subclase}</td>
													<td align={"center"}>{item.proveedor}</td>
													<td align={"center"}>{item.beneficiario_partida}</td>
													<td align={"center"}>{item.cantidad}</td>
													<td align={"center"}>${FieldsJs.Currency(item.precio_unitario)}</td>
													<td align={"center"}>{FieldsJs.Currency(item.importe)}</td>
													<td align={"center"}>{Number(item.factura) === 1 ? 'Si' : 'No'}</td>
													<td align={'right'}>
														<div className={'w-auto vertical-inline h-100'}>
															<div className={'v-center'}>
																{this.state.key === index ? (
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Partida en edición"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Editar"
																				onClick={() => this.editar_partida(item, index)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "#f50057",
																					color: "white",
																				}}
																				children={(
																					<EditOutlined/>
																				)}
																			/>
																		)}
																	/>
																) : (
																	<Tooltip
																		TransitionComponent={Zoom}
																		placement={"top"}
																		title="Editar"
																		children={(
																			<Fab
																				size="small"
																				aria-label="Editar"
																				onClick={() => this.editar_partida(item, index)}
																				style={{
																					marginLeft: "10px",
																					backgroundColor: "transparent",
																					color: "#808080",
																					boxShadow: "none",
																				}}
																				children={(
																					<EditOutlined/>
																				)}
																			/>
																		)}
																	/>
																)}
															</div>
															<div className={'v-center'}>
														<Tooltip
															TransitionComponent={Zoom}
															placement={"top"}
															title="Eliminar"
															children={(
																<Fab
																	size="small"
																	aria-label="Eliminar"
																	onClick={() => this.eliminarPartida(item, index)}
																	style={{
																		marginLeft: "10px",
																		backgroundColor: "transparent",
																		color: "#808080",
																		boxShadow: "none",
																	}}
																	children={(
																		<DeleteOutlined/>
																	)}
																/>
															)}
														/>
															</div>
														</div>
													</td>
												</tr>
											)
										)}
										</tbody>
									</table>
								</Grid>
							) : null}
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<br/>
							</Grid>
						</Grid>
					
					</DialogContent>
					
					
					<DialogActions>
						<BotonAccionFooterModal
							id={this.state.id_solicitud_pago}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSolicitudAceptada.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	ListUsuario: PropTypes.func.isRequired,
	ListProveedor: PropTypes.func.isRequired,
	ListBanco: PropTypes.func.isRequired,
	ListTipoPago: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	ListEtapaProyecto: PropTypes.func.isRequired,
	ListSubEtapaProyecto: PropTypes.func.isRequired,
	ListClase: PropTypes.func.isRequired,
	ListSubClase: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
};

export default ModalSolicitudAceptada;
