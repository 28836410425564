import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {Add, DateRangeOutlined, Domain, HdrStrong, HowToVoteOutlined, Person, PersonOutlined, SearchOutlined} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {FieldsJs} from '../../../../settings/General/General';
import Fab from "@material-ui/core/Fab";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import MDDatePicker from "../../../Include/MDDatePicker/MDDatePicker";


class FiltrosSolicitudPago extends Component {
	
	state = {};
	
	constructor() {
		super();
		this.state = {
			no_recibo: '',
			usuario: '',
			activo: '',
			id_cat_estatus_solicitud_pago: '',
			fecha_solicitud_inicio: null,
			fecha_solicitud_fin: null,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
		setTimeout(() => this.HandleFiltro());
	};
	
	HandleFiltro = () => {
		let filtro = {};
		
		filtro.no_recibo = this.state.no_recibo || null;
		filtro.usuario = this.state.usuario || null;
		filtro.id_cat_estatus_solicitud_pago = null;
		filtro.fecha_solicitud_inicio = this.state.fecha_solicitud_inicio || null;
		filtro.fecha_solicitud_fin = this.state.fecha_solicitud_fin || null;
		filtro.activo = this.state.activo || null;
		
		this.props.HandleFiltro(filtro);
	};
	
	AplicarFiltro = () => {
		this.props.AplicarFiltros();
	};
	
	
	render() {
		return (
			<Fragment>
				<Grid container spacing={2} alignContent={"flex-end"} alignItems={"flex-end"}>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={2} lg={2} xl={2}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HowToVoteOutlined className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField type={'text'} fullWidth name="no_recibo"
								           onChange={this.handleChange}
								           inputProps={{maxLength: 13}}
								           label="No. Recibo" autoComplete={'off'}
								           value={this.state.no_recibo}
								           disabled={this.props.tipo === 'view'}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item className={'text-left'} xs={12} sm={6} md={3} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<Person className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField type={'text'} fullWidth name="usuario"
								           onChange={this.handleChange}
								           inputProps={{maxLength: 13}}
								           label="Solicitante" autoComplete={'off'}
								           value={this.state.usuario}
								           disabled={this.props.tipo === 'view'}
								/>
							</Grid>
						</Grid>
					</Grid>
					
					<Grid item xs={6} sm={6} md={4} lg={3} xl={3} align={'left'}>
						<MDDatePicker
							type={'range'}
							component={(
								<TextField
									fullWidth
									label="Fecha de solicitud"
									value={(this.state.fecha_solicitud_inicio && this.state.fecha_solicitud_fin) ? (DateFormat.FormatText(this.state.fecha_solicitud_inicio) + " al " + DateFormat.FormatText(this.state.fecha_solicitud_fin)) : ''}
									readOnly
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton aria-label="Fecha de solicitud">
													<DateRangeOutlined/>
												</IconButton>
											</InputAdornment>
										)
									}}
								/>
							)}
							startDate={this.state.fecha_solicitud_inicio}
							endDate={this.state.fecha_solicitud_fin}
							onChange={(range) => {
								this.setState({
									fecha_solicitud_inicio: range.startDate,
									fecha_solicitud_fin: range.endDate,
								});
								setTimeout(() => this.HandleFiltro());
							}}
						/>
					</Grid>
					
					{/*<Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
						<Grid container spacing={1} alignItems={"flex-end"}>
							<Grid item className={'w-30-px'}>
								<HdrStrong className={'w-100 text-gray'}/>
							</Grid>
							<Grid item className={'w-100-30-px'}>
								<TextField
									select
									fullWidth
									margin="none"
									onChange={this.handleChange}
									SelectProps={{
										native: true,
										MenuProps: {
											className: '',
										},
									}}
									
									name="id_cat_estatus_solicitud_pago"
									label="Estatus"
									value={this.state.id_cat_estatus_solicitud_pago}
								>
									<option key={0} value={''}>&nbsp;</option>
									{this.props.catEstatus.map(option => (
										<option key={option.id_cat_estatus_solicitud_pago}
										        value={option.id_cat_estatus_solicitud_pago}>
											{option.estatus}
										</option>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>*/}
					
					
					<Grid item xs={12} sm={12} md={12} lg={1} xl={1} align={'right'}>
						<Fab variant="extended" size="small" color="default" aria-label="Add"
						     onClick={this.AplicarFiltro}>
							<SearchOutlined className={'margin-5-R px-14'}/> Buscar
						</Fab>
					</Grid>
				
				</Grid>
			</Fragment>
		);
	}
}

FiltrosSolicitudPago.propTypes = {
	HandleFiltro: PropTypes.func.isRequired,
	AplicarFiltros: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	lista_usuario: PropTypes.array.isRequired,
	catEstatus: PropTypes.array.isRequired
};

export default FiltrosSolicitudPago;
