import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";

import Divider from "@material-ui/core/Divider";
import DraggableModal from "../../../../Include/DraggableModal/DraggableModal";
import {FieldsJs} from "../../../../../settings/General/General";
import Slide from "@material-ui/core/Slide";
import SubEtapaProyectoService from "../../../../../services/_Cat/SubEtapaProyectoService/SubEtapaProyectoService";
import {
	BotonAccionAbrirModal,
	BotonAccionFooterModal,
	TituloHeaderModal
} from "../../../../Include/MiniComponents/GlobalComponent";
import {AccountTree, AddOutlined, HdrWeakOutlined, HowToVoteOutlined} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import {PopupService} from "../../../../../settings/PoPup/PoPup";
import {CONFIG} from "../../../../../settings/Config/Config";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import Table from "@material-ui/core/Table";
import EtapaProyectoService from "../../../../../services/_Cat/EtapaProyectoService/EtapaProyectoService";

class ModalSubEtapaProyecto extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		
		this.state = {
			modal: {
				open: false
			},
			
			tipo: props.tipo,
			
			id_cat_etapa_proyecto: '',
			id_cat_subetapa_proyecto: (props.item || '').id_cat_subetapa_proyecto,
			id_cat_area_proyecto: '',
			etapa: '',
			subetapa: '',
			descripcion: '',
			cat_area_proyecto: [],
			cat_etapa_proyecto: [],
			subetapas: (props.item || []).subetapas || [],
			activo: true,
		};
	}
	
	handleChange = (e, variable, campo, date, input) => {
		FieldsJs.HandleChange(e, variable, campo, date, input, (r) => this.setState({
			[r.name]: r.value
		}));
	};
	
	changeValue = (arr_name__key, var_name, var_value) => {
		FieldsJs.ChangeValue(arr_name__key, var_name, var_value, (r) => this.setState({
			[r.name]: r.value
		}), this.state);
	};
	
	closeModal = () => {
		this.setState({
			modal: {
				open: false
			}
		});
	};
	
	close = () => {
		this.closeModal();
	};
	
	openModal = () => {
		const props = this.props;
		this.setState({
			modal: {
				open: true
			}
		});
		console.log(this.state, props);
	};
	
	open = () => {
		this.init().then(r => {
			setTimeout(() => this.openModal());
		}).catch(e => {
			console.log(e);
		});
	};
	
	RefreshInit = () => {
		this.init();
	};
	
	init = () => {
		return new Promise((resolve) => {
			resolve(true);
		});
	};
	
	save = () => {
		if (this.state.id_cat_etapa_proyecto > 0 && this.props.tipo !== 'add') {
			SubEtapaProyectoService.Modificar(this.state).then((response) => {
				this.close();
				this.props.closeModal();
				this.props.RefreshList();
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		} else {
			SubEtapaProyectoService.Agregar(this.state).then((response) => {
				this.close();
				this.props.RefreshList(response.data);
				this.props.showSnackBars('success', response.mensaje);
			}).catch((error) => {
				this.props.showSnackBars('error', error.mensaje);
			});
		}
	};
	
	add = () => {
		const {item} = this.props;
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		
		console.log(this.props);
		this.setState({
			id_cat_subetapa_proyecto: '',
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	edit = () => {
		const {item} = this.props;
		console.log(this.props);
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || '',
			});
		});
		if(FieldsJs.Field(item.id_cat_etapa_proyecto)) {
			this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
				this.setState({
					cat_etapa_proyecto: response.data,
					id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
				});
			});
		} else {
			this.setState({
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			});
		}
		
		this.setState({
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: item.descripcion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	view = () => {
		const {item} = this.props;
		console.log(this.props);
		this.props.ListAreaProyecto().then((response) => {
			this.setState({
				cat_area_proyecto: response.data,
				id_cat_area_proyecto: item.id_cat_area_proyecto || ''
			});
		});
		
		if(FieldsJs.Field(item.id_cat_area_proyecto)) {
			this.props.ListEtapaProyecto(item.id_cat_area_proyecto).then((response) => {
				this.setState({
					cat_etapa_proyecto: response.data,
					id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
				});
			});
		} else {
			this.setState({
				id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			});
		}
		
		this.setState({
			id_cat_etapa_proyecto: item.id_cat_etapa_proyecto || '',
			etapa: item.etapa || '',
			subetapa: item.subetapa || '',
			subetapas: item.subetapas || [],
			descripcion: item.descripcion || '',
			activo: (item.activo === 1),
		});
		this.open();
	};
	
	agregarPartida = () => {
		try {
			
			if (!this.state.descripcion) {
				throw Object({
					status: false, mensaje: 'Campo requerido: Sub Etapa.'
				})
			}
			
			let partidas = FieldsJs.Copy(this.state.descripcions);
			
			partidas.push(this.state.descripcion || '');
			
			this.setState({
				descripcion: '',
				descripcions: partidas,
			});
			
		} catch (e) {
			this.props.showSnackBars('error', e.mensaje);
		}
	};
	
	
	eliminarPartida = (value, key) => {
		try {
			PopupService.Confirm(['Cancelar', 'Aceptar'], 'warning', CONFIG.titulo_alert_confirm, '¿Deseas eliminar la sub-etapa?').then((r) => {
				if (r.button === 'Aceptar') {
					let partidas = FieldsJs.Copy(this.state.descripcions);
					
					partidas.splice(key, 1);
					
					this.setState({
						descripcions: partidas
					});
				}
			});
			
		} catch (error) {
			this.showSnackBars('error', error.mensaje);
		}
	};
	
	eliminar = (item) => {
		let msg = `¿Deseas eliminar el sub-etapa ${item.subetapa}?`;
		PopupService.Confirm(['Cancelar', 'Eliminar'], 'warning', CONFIG.titulo_alert_confirm, msg).then((r) => {
			if (r.button === 'Eliminar') {
				EtapaProyectoService.Eliminar(item.id_cat_subetapa_proyecto).then((response) => {
					this.props.showSnackBars('success', response.mensaje);
					this.props.RefreshList();
				}).catch((error) => {
					this.props.showSnackBars('error', error.mensaje);
				});
			}
		});
	};
	
	etapaProyecto = (id_cat_area_proyecto) => {
		this.props.ListEtapaProyecto(id_cat_area_proyecto).then((response) => {
			this.setState({
				cat_etapa_proyecto: response.data
			});
		});
	}
	
	handleEtapaProyecto = (id_cat_etapa_proyecto) => {
		let ep = FieldsJs.Copy(this.state.cat_etapa_proyecto);
		for (let i = 0; i < ep.length; i++) {
			if(Number(id_cat_etapa_proyecto) === Number(ep[i].id_cat_etapa_proyecto)) {
				this.setState({
					etapa: ep[i].etapa
				});
			}
		}
	}
	
	render() {
		
		let funcion;
		
		switch (this.state.tipo) {
			case 'edit':
				funcion = this.edit;
				break;
			case 'add':
				funcion = this.add;
				break;
			case 'view':
				funcion = this.view;
				break;
			default:
				funcion = () => {
				};
		}
		
		
		return (
			
			<div>
				
				{this.props.componente ? (
					<Fragment>
						<span
							style={{cursor: 'pointer'}}
							children={this.props.componente}
							onClick={() => funcion()}
						/>
					</Fragment>
				) : (
					<BotonAccionAbrirModal
						id={this.state.id_cat_subetapa_proyecto}
						tipo={this.state.tipo}
						onClick={() => funcion()}
					/>
				)}
				
				<Dialog open={this.state.modal.open} onClose={() => this.close()} PaperComponent={DraggableModal}
				        disableEscapeKeyDown disableBackdropClick maxWidth={'sm'} fullWidth={true} scroll={'paper'}
				        transition={<Slide direction="up"/>} aria-labelledby="scroll-dialog-title"
				>
					
					<DialogTitle>
						<TituloHeaderModal
							tipo={this.state.tipo}
							titulo={'Sub-Etapa'}
						/>
					</DialogTitle>
					
					<DialogContent>
						
						<Grid container spacing={1}>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<AccountTree className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Proyecto</InputLabel>
											<Select
												native
												value={this.state.id_cat_area_proyecto}
												onChange={(e) => {
													this.handleChange(e);
													this.etapaProyecto(e.target.value);
												}}
												name="id_cat_area_proyecto"
												input={<Input/>}
											>
												<option value=""/>
												{this.state.cat_area_proyecto.map(option => (
													<option key={option.id_cat_area_proyecto}
													        value={option.id_cat_area_proyecto}>
														{option.area_proyecto}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item className={'text-left'} xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container spacing={1} alignItems={"flex-end"}>
									<Grid item className={'w-30-px'}>
										<HdrWeakOutlined className={'w-100 text-gray'}/>
									</Grid>
									<Grid item className={'w-100-30-px'}>
										<FormControl fullWidth>
											<InputLabel>Etapa</InputLabel>
											<Select
												native
												value={this.state.id_cat_etapa_proyecto}
												onChange={(e) => {
													this.handleChange(e);
													this.handleEtapaProyecto(e.target.value);
												}}
												name="id_cat_etapa_proyecto"
												input={<Input/>}
											>
												<option value=""/>
												{this.state.cat_etapa_proyecto.map(option => (
													<option key={option.id_cat_etapa_proyecto}
													        value={option.id_cat_etapa_proyecto}>
														{option.etapa}
													</option>
												))}
											
											</Select>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="subetapa"
									label="Sub-Etapa"
									type="text"
									fullWidth
									value={this.state.subetapa}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<TextField
									multiline
									margin="dense"
									name="descripcion"
									label="Descripción"
									type="text"
									fullWidth
									value={this.state.descripcion}
									onChange={this.handleChange}
									disabled={this.state.tipo === 'view'}
								/>
							
							</Grid>
							
							{this.state.subetapas.length > 0 ? (
								<Fragment>
									<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
										<br></br>
									</Grid>
									<Grid item xs={12} sm={6} md={2} lg={1} xl={1} align={'right'}>
										<Table className={'desing-acresco'}>
											<TableHead>
												<TableRow>
													<TableCell component="th" align={'center'} className={'w-10-px padding-20-L padding-10-R'}>
														ID
													</TableCell>
													<TableCell component="th" align={'left'}>Etapa</TableCell>
													<TableCell component="th" align={'left'}>Descripción</TableCell>
													<TableCell component="th" align={'center'} className={'w-110-px'}>Acciones</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.state.subetapas.map((item, key) => (
													<TableRow key={key}>
														<TableCell align={'center'} className={'padding-20-L padding-20-R'}>
															{item.id_cat_subetapa_proyecto}
														</TableCell>
														<TableCell align={'left'}>{item.subetapa}</TableCell>
														<TableCell align={'left'}>{item.descripcion}</TableCell>
														<TableCell align={'center'} className={'padding-5-L padding-5-R'}>
															<div className={'w-auto vertical-inline'}>
																<div className={'v-center'}>
																	<Tooltip TransitionComponent={Zoom} placement={"top"} title="Eliminar">
																		<IconButton aria-label="Eliminar"
																		            onClick={() => this.eliminar(item)}>
																			<DeleteOutlined/>
																		</IconButton>
																	</Tooltip>
																</div>
																<div className={'v-center'}>
																	<ModalSubEtapaProyecto
																		tipo={'edit'}
																		item={item}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																		ListAreaProyecto={this.props.ListAreaProyecto}
																		closeModal={this.closeModal}
																	/>
																</div>
																<div className={'v-center'}>
																	<ModalSubEtapaProyecto
																		tipo={'view'}
																		item={item}
																		RefreshList={this.props.RefreshList}
																		showSnackBars={this.props.showSnackBars}
																		ListAreaProyecto={this.props.ListAreaProyecto}
																		closeModal={this.closeModal}
																	/>
																</div>
															</div>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</Grid>
								</Fragment>
							):null}
						
							{/*<Grid item xs={12} sm={6} md={2} lg={1} xl={1} align={'right'}>
								<div className={'w-auto vertical-inline h-100'}>
									<div className={'v-center'}>
										<Tooltip
											TransitionComponent={Zoom}
											placement={"top"}
											title={"Agregar"}
											children={(
												<Fab size="small" color="primary"
												     aria-label={"Agregar"}
												     onClick={() => this.agregarPartida()}
												     style={{marginLeft: "15px"}}>
													<AddOutlined/>
												</Fab>
											)}
										/>
									</div>
								</div>
							</Grid>
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
								{this.state.descripcions.map(item => (
									<Chip label={item} onDelete={() => this.eliminarPartida(item)} color="primary"/>
								))}
							</Grid>
							*/}
							
							
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								
								<FormGroup row className={'margin-3-L'}>
									<FormControlLabel
										control={
											<Checkbox
												type="checkbox"
												disabled={this.state.tipo === 'view'}
												onChange={this.handleChange}
												color="primary"
												name='activo'
												checked={this.state.activo}
												value="activo"
											/>
										}
										label={'Activo'}
									/>
								</FormGroup>
							
							</Grid>
						
						</Grid>
					
					</DialogContent>
					
					<Divider/>
					
					<DialogActions>
						
						<BotonAccionFooterModal
							id={this.state.id_cat_etapa_proyecto}
							tipo={this.state.tipo}
							close={this.close}
							save={this.save}
						/>
					
					</DialogActions>
				
				</Dialog>
			
			</div>
		);
	}
}

ModalSubEtapaProyecto.propTypes = {
	componente: PropTypes.element,
	tipo: PropTypes.string.isRequired,
	item: PropTypes.object.isRequired,
	RefreshList: PropTypes.func.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	ListAreaProyecto: PropTypes.func.isRequired,
	ListEtapaProyecto: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default ModalSubEtapaProyecto;
