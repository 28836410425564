import React, {Component, Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {$cWarn, FieldsJs} from "../../../../settings/General/General";
import {ReactLocalStorageService} from "../../../../settings/ReactLocalStorageService/ReactLocalStorageService";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {DateFormat} from "../../../../settings/DateFormat/DateFormat";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import IconButton from "@material-ui/core/IconButton";
import {InsertDriveFile} from "@material-ui/icons";

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(1) * 3,
		overflowX: 'auto',
	},
	table: {
		minWidth: 600,
	},
});


class SubTablaSolicitudPago extends Component {
	state = {};
	Usr = {};
	
	constructor(props) {
		super(props);
		this.Usr = ReactLocalStorageService.get('Usr') || {};
		this.state = {
			id_cat_ficha_tecnica_status: props.item.id_cat_ficha_tecnica_status,
			listar_timbrados: props.item.lista || [],
		}
	}
	
	componentDidMount() {
		$cWarn("componentDidMount");
	}
	
	componentWillUnmount() {
		$cWarn("componentWillUnmount");
	}
	
	render() {
		
		
		const {classes} = this.props;
		
		return (
			<Fragment>
				<div className={'bg-white card-1'}>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography className={'px-14 text-center bg-white padding-5'}>
							Partida(s)
						</Typography>
					</Grid>
					
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Table className={classes.table + ' desing-integraciones'}>
							<TableHead>
								<TableRow>
									<TableCell component="th" align={'left'} style={{paddingLeft: '15px'}}>Beneficiario</TableCell>
									<TableCell component="th" align={'left'}>Proyecto</TableCell>
									<TableCell component="th" align={'left'}>Descripción</TableCell>
									<TableCell component="th" align={'left'}>Proveedor</TableCell>
									<TableCell component="th" align={'left'}>Cantidad</TableCell>
									<TableCell component="th" align={'left'}>Precio Unitario</TableCell>
									<TableCell component="th" align={'left'}>Importe</TableCell>
									<TableCell component="th" align={'left'}>Factura</TableCell>
									<TableCell component="th" align={'left'}>Tipo de Pago</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.listar_timbrados.map((item, key) => {
									return (
										<Fragment key={key}>
											<TableRow>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''} style={{paddingLeft: '15px'}}>{item.beneficiario}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.area_proyecto}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.descripcion}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.proveedor}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.cantidad}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.precio_unitario)}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>${FieldsJs.Currency(item.importe)}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{Number(item.factura) === 1 ? 'Si' : 'No'}</TableCell>
												<TableCell align={'left'} className={Number(item.id_cat_facturacion_status_sat) === 2 ? 'text-tachado' : ''}>{item.tipo_pago}</TableCell>
											</TableRow>
										</Fragment>
									);
								})
								}
							</TableBody>
						</Table>
					</Grid>
				</div>
			</Fragment>
		);
	}
}

SubTablaSolicitudPago.propTypes = {
	// lista: PropTypes.array.isRequired,
	item: PropTypes.object.isRequired,
	showSnackBars: PropTypes.func.isRequired,
	RefreshList: PropTypes.func.isRequired
};

export default withStyles(styles)(SubTablaSolicitudPago);
